class WpiConstants {
  static BASE_URL = "https://api.wealthpulse.in/";
  static PORTFOLIO_CREATE_URL = this.BASE_URL + "cr/create-portfolio";
  static GET_PORTFOLIO_INDEX_URL = this.BASE_URL + "idx/get-portfolio-index";
  static GET_LTP_URL = this.BASE_URL + "ltp/getltp";
  static GET_FOLLOWERS_URL = this.BASE_URL + "flr/get-followers";
  static GET_TICKER_LIST_API_GW = this.BASE_URL + "list/getStockList";

  static GET_SECTOR_INFO_URL = this.BASE_URL + "str/sector-info";
  static GET_TOP_PORTFOLIO_URL = this.BASE_URL + "top/get-top-portfolios";
}

export default WpiConstants;
