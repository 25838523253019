import _ from "lodash";

import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";

import { createChart, ColorType, LineType } from "lightweight-charts";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import {
  fetchPortfolioData,
  resetData,
} from "../../../redux/portfoliodataslice";
import PortfolioChart from "./PortfolioChart";
import LoggedInTopNavbar from "../../Nav/LoggedInTopNavbar";

export const PortfolioLanding = () => {
  //   const { id } = useParams(); // Access URL parameter

  // const isUserLoggedIn = useSelector((state) => {
  //   // console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
  //   return state.auth.isLoggedIn;
  // });

  const isUserLoggedIn = true;

  const dispatch = useDispatch();
  const landing = useLocation();
  console.log("PortfolioLanding location is : ", landing);

  const portfolioFetchLoading = useSelector(
    (state) => state.portfolioData.loading
  );

  const idxData = useSelector((state) => state.portfolioData.idx);

  const smaData = useSelector((state) => state.portfolioData.sma);

  const portfolioInfo = useSelector((state) => state.portfolioData.info);

  console.log("portfolioInfo : ", portfolioInfo);

  const portfolioOhlcData = useSelector(
    (state) => state.portfolioData.portfolioOhlc
  );

  const portfolioIdxData = useSelector(
    (state) => state.portfolioData.portfolioCloseArr
  );

  const portfolioMeta = useSelector((state) => state.portfolioData.meta);

  const portfolioDailyRet = useSelector(
    (state) => state.portfolioData.dailyRet
  );

  const portfolioMonthlyRet = useSelector(
    (state) => state.portfolioData.monthlyRet
  );

  const portfolioYearlyRet = useSelector(
    (state) => state.portfolioData.yearlyRet
  );

  const tableRef = useRef(null);

  console.log("portfolioFetchLoading is : ", portfolioFetchLoading);
  console.log("portfolioIdx : ", portfolioIdxData);
  console.log("portfolio ohlc : ", portfolioOhlcData);
  console.log("meta : ", portfolioMeta);
  console.log("dailyRet : ", portfolioDailyRet);
  console.log("monthly ret : ", portfolioMonthlyRet);
  console.log("yearly ret : ", portfolioYearlyRet);

  useEffect(() => {
    // if (postStatus === 'idle') {
    //   dispatch(fetchPosts())
    // }
    console.log(
      "useEffect : isUserLoggedIn : ",
      isUserLoggedIn,
      " landing : ",
      landing,
      " portfolioIdxData : ",
      portfolioIdxData
    );

    dispatch(resetData());

    if (isUserLoggedIn && landing != null && landing.state != null) {
      const id = landing.state["portfolioId"];
      // const id = "";
      console.log("portfolio id is : ", id);
      const ret = dispatch(fetchPortfolioData(id));
    }
  }, []);

  //   console.log("PortfolioLanding id is : ", id);

  return (
    <div class="w-full p-4 my-4  rounded-lg  dark:bg-gray-800">
      <LoggedInTopNavbar />
      <div className="mt-8">
        {isUserLoggedIn ? (
          <div className="bg-gray-100">
            {portfolioFetchLoading ? (
              <div> Portfolio Loading </div>
            ) : (
              <div className="w-full flex flex-col m-0 md:m-5 p-2">
                <div className="w-full items-center flex flex-col md:flex-col ">
                  <div className="w-full md:w-2/d items-center text-center text-lg font-bold m-8 flex flex-col md:flex-col ">
                    <div>{portfolioInfo ? portfolioInfo["name"] : ""}</div>
                    <div>
                      {portfolioInfo ? (
                        <GetPortfolioComponent
                          componentsArr={portfolioInfo["components"]}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:w-2/3 " ref={tableRef}>
                    {getPortfolioMetricsTable(portfolioMeta)}
                  </div>
                  <div className="w-full md:w-2/3 flex-10">
                    {portfolioOhlcData && portfolioOhlcData.length > 0 ? (
                      <PortfolioChart
                        className="flex-0 w-full border border-red-500"
                        idx={idxData}
                        portfolioIdx={portfolioIdxData}
                        portfolioOhlc={portfolioOhlcData}
                        idxChartWidth={tableRef}
                        smaData={smaData}
                      />
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
                <div className="w-full p-4">
                  {portfolioYearlyRet ? (
                    <GetPortfolioYearlyReturnTable
                      portfolioYearlyRet={portfolioYearlyRet}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/2 p-4">
                    {portfolioMonthlyRet ? (
                      <GetPortfolioMonthlyReturnTable
                        portfolioMonthlyRet={portfolioMonthlyRet}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 p-4">
                    {portfolioDailyRet ? (
                      <GetPortfolioDailyReturnTable
                        portfolioDailyRet={portfolioDailyRet}
                      />
                    ) : (
                      <></>
                    )}
                    {/* {GetPortfolioDailyReturnTable(portfolioDailyRet)} */}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Navigate to="/" />
        )}
      </div>
    </div>
  );
};

const GetPortfolioComponent = ({ componentsArr }) => {
  console.log("componentsArr : ", componentsArr);
  const [isTableVisible, setIsTableVisible] = useState(false);

  // Function to toggle table visibility
  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  if (componentsArr == null || componentsArr.length == 0) {
    return <div></div>;
  }

  const mainUI = () => {
    return (
      <div>
        <table
          id="table-component"
          class="  w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8 mb-5"
        >
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className=" border border-black bg-black text-white text-2xl">
              <th
                key={0}
                scope="col"
                className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
              >
                Ticker
              </th>
              <th
                key={1}
                scope="col"
                className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
              >
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {componentsArr.map((item, index) => (
              <tr
                scope="row"
                class="border border-black  bg-white dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  class="border border-black md:px-6 px-2  md:pl-20 py-4 text-sm md:text-xs font-extrabold text-gray-900 whitespace-nowrap dark:text-white bg-violet-200"
                >
                  {item["ticker"]}
                </th>

                <th
                  scope="row"
                  className="px-6 font-extrabold md:pl-20 text-sm md:text-xs text-black bg-white"
                >
                  {item["qty"]}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div className="mt-10  ml-5 mb-5 text-lg" onClick={toggleTableVisibility}>
        Click here to view the Portfolio Component &nbsp;
        <Link className="text-violet-900 text-sm font-thin">
          {isTableVisible ? "Hide" : "Show"}
        </Link>
      </div>
      {isTableVisible && mainUI()}
    </div>
  );
};

const getPortfolioMetricsTable = (portfolioMeta) => {
  if (portfolioMeta == null) {
    return;
  }
  console.log(
    "getPortfolioMetricsTable : portfolioMeta : ",
    portfolioMeta,
    " len : ",
    Object.keys(portfolioMeta).length
  );
  if (portfolioMeta == null || Object.keys(portfolioMeta).length == 0) {
    return <div></div>;
  }
  let headingArr = portfolioMeta[0];
  let tableBodyData = portfolioMeta.slice(1);
  let headingKeys = Object.values(headingArr);
  console.log("headingKeys : ", headingKeys);

  console.log("tableBodyData is : ", tableBodyData);

  tableBodyData.map((item, index) => {
    console.log(
      "item : ",
      item,
      " length : ",
      item.length,
      " 0 ",
      item[0],
      " 1 : ",
      item[1],
      " 2 : ",
      item[2]
    );
  });

  return (
    <div>
      <table
        id="table-report"
        class="  w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8 mb-5"
      >
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className=" border border-black bg-black text-white text-2xl">
            {headingKeys.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableBodyData.map((item, index) => (
            <tr
              scope="row"
              class="border border-black  bg-white dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                class="border border-black md:px-6 px-2  md:pl-20 py-4 text-sm md:text-xs font-extrabold text-gray-900 whitespace-nowrap dark:text-white bg-violet-200"
              >
                {item[0]}
              </th>
              {getColoredCell(item[0], item[1])}

              {item.length > 2 ? getColoredCell(item[0], item[2]) : <></>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getColoredCell = (firstItem, item) => {
  return (
    <th className="border border-black">
      {firstItem == "Max Drawdown" || firstItem == "Max Loss | 2020 onwards" ? (
        <div className=" px-6 font-extrabold md:pl-20 text-sm md:text-xs text-red-600 bg-white">
          {item}
        </div>
      ) : (
        <div className=" px-6 font-extrabold md:pl-20 text-sm md:text-xs text-black bg-white">
          {item}
        </div>
      )}
    </th>
  );
};

const GetPortfolioDailyReturnTable = ({ portfolioDailyRet }) => {
  console.log("GetPortfolioDailyReturnTable : ", portfolioDailyRet);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Set the number of rows per page

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  if (portfolioDailyRet == null || portfolioDailyRet.length == 0) {
    return <div></div>;
  }

  let headingArr = portfolioDailyRet[0];
  let tableBodyData = portfolioDailyRet.slice(1);
  let headingKeys = Object.values(headingArr);
  console.log("headingKeys : ", headingKeys);

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableBodyData.length / rowsPerPage);

  // Get the current rows to display
  const currentRows = tableBodyData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div>
      <div className="text-black font-bold text-lg text-center">
        Daily Return
      </div>
      <div>
        <table
          id="table-report-daily"
          class="  w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8 mb-5"
        >
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="border border-black bg-black text-white text-xs">
              {headingKeys.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((item, index) => (
              <tr
                key={index}
                scope="row"
                class="border border-black  bg-white dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  class="border border-black md:px-6 px-2  md:pl-20 py-4 text-sm md:text-xs font-extrabold text-gray-900 whitespace-nowrap dark:text-white bg-violet-200"
                >
                  {item[0]}
                </th>
                <th className=" px-6 font-extrabold md:pl-20 text-sm md:text-xs text-black bg-white">
                  {item[1]}
                </th>
                <th
                  className={`px-6 font-extrabold md:pl-20 text-sm ${
                    item[2] < 0 ? "text-red-600" : "text-black"
                  } bg-white`}
                >
                  {item[2]}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between w-full md:w-full mt-4">
        <button
          onClick={handlePrevious}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const GetPortfolioMonthlyReturnTable = ({ portfolioMonthlyRet }) => {
  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const [currentPage, setCurrentPage] = useState(1);

  console.log("GetPortfolioMonthlyReturnTable : ", portfolioMonthlyRet);
  if (portfolioMonthlyRet == null || portfolioMonthlyRet.length == 0) {
    return <div></div>;
  }

  let headingArr = portfolioMonthlyRet[0];
  let tableBodyData = portfolioMonthlyRet.slice(1);
  let headingKeys = Object.values(headingArr);
  console.log("headingKeys : ", headingKeys);

  const rowsPerPage = 10; // Set the number of rows per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableBodyData.length / rowsPerPage);

  // Get the current rows to display
  const currentRows = tableBodyData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div className="w-full  text-center">
      <div className="text-black font-bold text-lg"> Monthly Return </div>
      <div className="w-full ">
        <table
          id="table-report-monthly"
          class="  w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8 mb-5"
        >
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="border border-black bg-black text-white text-xs">
              {headingKeys.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((item, index) => (
              <tr
                key={index}
                scope="row"
                class="border border-black  bg-white dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  class="border border-black md:px-6 px-2  md:pl-20 py-4 text-sm md:text-xs font-extrabold text-gray-900 whitespace-nowrap dark:text-white bg-violet-200"
                >
                  {item[0]}
                </th>
                <th className=" px-6 font-extrabold md:pl-20 text-sm md:text-xs text-black bg-white">
                  {item[1]}
                </th>
                <th
                  className={`px-6 font-extrabold md:pl-20 text-sm ${
                    item[2] < 0 ? "text-red-600" : "text-black"
                  } bg-white`}
                >
                  {item[2]}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between w-full md:w-full mt-4">
        <button
          onClick={handlePrevious}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const GetPortfolioYearlyReturnTable = ({ portfolioYearlyRet }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Set the number of rows per page

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const [isTableVisible, setIsTableVisible] = useState(false);

  if (portfolioYearlyRet == null || portfolioYearlyRet.length == 0) {
    return <div></div>;
  }

  // Function to toggle table visibility
  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  let headingArr = portfolioYearlyRet[0];
  let tableBodyData = portfolioYearlyRet.slice(1);
  let headingKeys = Object.values(headingArr);
  console.log("headingKeys : ", headingKeys);

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableBodyData.length / rowsPerPage);

  // Get the current rows to display
  const currentRows = tableBodyData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const mainUI = () => {
    return (
      <div className="w-full md:w-1/2 p-0 ">
        <div className="text-black  text-center font-bold text-lg">
          Yearly Return
        </div>
        <div className="w-full ">
          <table
            id="table-report-yearly"
            class="  w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8 mb-5"
          >
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="border border-black bg-black text-white text-xs">
                {headingKeys.map((item, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((item, index) => (
                <tr
                  key={index}
                  scope="row"
                  class="border border-black  bg-white dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    class="border border-black md:px-6 px-2  md:pl-20 py-4 text-sm md:text-xs font-extrabold text-gray-900 whitespace-nowrap dark:text-white bg-violet-200"
                  >
                    {item[0]}
                  </th>
                  <th className=" px-6 font-extrabold md:pl-20 text-sm md:text-xs text-black bg-white">
                    {item[1]}
                  </th>
                  <th
                    className={`px-6 font-extrabold md:pl-20 text-sm ${
                      item[2] < 0 ? "text-red-600" : "text-black"
                    } bg-white`}
                  >
                    {item[2]}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="mt-10  ml-5 mb-5 text-lg" onClick={toggleTableVisibility}>
        Click here to view the yearly return &nbsp;
        <Link className="text-violet-900 text-sm font-thin">
          {isTableVisible ? "Hide Table" : "Show Table"}
        </Link>
      </div>
      {isTableVisible && mainUI()}
    </div>
  );
};
