import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import FullButton from "../Buttons/FullButton";

// Components
import Sidebar from "./Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { withAuthenticator } from "@aws-amplify/ui-react";

import { Button } from "semantic-ui-react";
import { signOut } from "@aws-amplify/auth";
import { Navigate } from "react-router-dom";

// export default withAuthenticator(LoggedInTopNavbar)

export default function LoggedInTopNavbar({ signout, user, t }) {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  // setTimeout(()=>{ setIsLoggedIn(true); }, 10);

  // console.log("user is : ", user);

  const onSignOut = () => {
    console.log("onSignout Clicked");
    try {
      // await Auth.signOut()
      setIsLoggedIn(false);

      // signOut();

      // signout success
    } catch (e) {
      // signout failed
    }
  };

  const LoggedIn = () => {
    // console.log("LoggedIn");
    return (
      <>
        <Sidebar
          id="sidebar"
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        <Wrapper
          id="topnavbar"
          className="flexCenter animate whiteBg"
          style={y > 100 ? { height: "60px" } : { height: "80px" }}
        >
          <NavInner className="container flexSpaceCenter">
            <Link className="pointer flexNullCenter" to="/" smooth={true}>
              <LogoIcon />
              <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                Wealth Pulse
              </h1>
            </Link>
            <BurderWrapper
              className="pointer"
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <BurgerIcon />
            </BurderWrapper>
            <UlWrapper className="flexNullCenter">
              <>
                <li className="semiBold font15 pointer text-lg">
                  <Link
                    activeClass="active"
                    style={{ padding: "10px 15px" }}
                    to="/"
                    spy={true}
                    smooth={true}
                    offset={-80}
                  >
                    Explore
                  </Link>
                </li>
                <li className="semiBold font15 pointer text-lg">
                  <Link
                    activeClass="active"
                    style={{ padding: "10px 15px" }}
                    to="/portfolio/create"
                    spy={true}
                    smooth={true}
                    offset={-80}
                  >
                    Create
                  </Link>
                </li>

                <li className="semiBold font15 pointer text-lg">
                  <Link
                    activeClass="active"
                    style={{ padding: "10px 15px" }}
                    to="/portfolio/sector"
                    spy={true}
                    smooth={true}
                    offset={-80}
                  >
                    Analyse
                  </Link>
                </li>
              </>

              {/* <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                Projects
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
                Blog
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
                Pricing
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li> */}
            </UlWrapper>
            <UlWrapperRight className="flexNullCenter">
              {/* <ButtonWrapper primary onClick={onSignOut}>
                {" "}
                Sign Out
              </ButtonWrapper> */}
              <li className="semiBold font15 pointer text-violet-600">
                <a
                  onClick={onSignOut}
                  className="text-lg"
                  // href="/login"
                  style={{ padding: "10px 30px 10px 0" }}
                >
                  Log out
                </a>
              </li>

              {/* <Button onClick={onSignOut} className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Sign Out
              </Button> */}
            </UlWrapperRight>
          </NavInner>
        </Wrapper>
      </>
    );
  };

  const LoggedOut = () => {
    console.log("LoggedOut");
    return <Navigate to="/logout" />;
  };

  return <div>{isLoggedIn ? LoggedIn() : LoggedOut()}</div>;
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const ButtonWrapper = styled(Button)`
  background-colo: red;
  max-width: 190px;
  height: 40px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const BtnWrapper = styled.div`
  background-colo: red;
  max-width: 190px;
  height: 40px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
