import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    markLoggedIn: (state) => {
      console.log("AuthReducer :: markLoggedIn");
      state.isLoggedIn = true;
    },
    markLoggedOut: (state) => {
      console.log("AuthReducer :: markLoggedOut");
      state.isLoggedIn = false;
      state.user = null;
      localStorage.clear();
    },

    setUser: (state, user) => {
      console.log("AuthReducer :: setUser :: ", user.payload);
      state.user = user.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { markLoggedIn, markLoggedOut, setUser } = authSlice.actions;

export default authSlice.reducer;
