import { useState, useEffect } from "react";
import WpiConstants from "../../../utils/wpiconstants";

import Avatar from "react-avatar";
import { Navigate } from "react-router-dom";

import { Spinner } from "flowbite-react";

import { Card } from "flowbite-react";

export default function TopTrendingPortfolios() {
  const [topPortfolioResponse, setTopPortfolioResponse] = useState(null);
  const [portfolioId, setPortfolioId] = useState(null);
  const [navigate, setNavigate] = useState();

  const top_portfolio_func = async () => {
    const top_portfolio_url = "";
    const top_portfolio_response = await fetch(
      WpiConstants.GET_TOP_PORTFOLIO_URL
    );
    if (top_portfolio_response.ok) {
      const json_data = await top_portfolio_response.json();
      console.log("top portfolios response is : ", json_data);

      setTopPortfolioResponse(json_data);
    }
  };

  useEffect(() => {
    top_portfolio_func();
  }, []);

  const openPortfolio = (id) => {
    console.log("Opening portfolio : ", id);
    setPortfolioId(id);
    // return <PortfolioLanding data={id} />;
    setNavigate("/portfolio/landing/");
  };

  const showSpiner = () => {
    return (
      <div className="w-screen h-40 flex items-center justify-center">
        <Spinner
          color="purple"
          aria-label="Extra small spinner example"
          size="xl"
        />
      </div>
    );
  };

  const mainUI = () => {
    return (
      <div className="w-screen mt-10 flex items-center justify-center ml-4 mr-4">
        <div className="flex overflow-x-auto space-x-4 p-0 bg-violet-100 border border-blue-400 rounded-lg">
          {topPortfolioResponse.map((item, index) => (
            <div
              key={index}
              className="flex-shrink-0  m-4 bg-gray-100 border border-blue-100 rounded-lg text-center"
              onClick={() => openPortfolio(item["id"])}
            >
              <div className="block max-w-sm p-4 h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                {/* {item[0]} */}
                <div className="flex flex-col m-0 p-0 items-center w-52  h-full ">
                  <Avatar name={item["name"]} size="100" round />

                  <div className="text-xl font-sans font-semibold mt-4 ">
                    <p className="line-clamp-2 min-h-[4rem]">{item["name"]}</p>
                  </div>

                  <div className="w-full h-0.5 bg-orange-500 mb-2 mt-2"></div>

                  <div className=" w-full m-0 p-2 sm:p-0 ">
                    <div className="mt-0 flex flex-row w-full m-0 p-0 items-center">
                      <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                        Sharpe Ratio &nbsp;
                      </div>
                      <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                        {item["Sharpe Ratio"]}
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row w-full m-0 p-0">
                      <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                        CAGR &nbsp;
                      </div>
                      <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                        {item["CAGR"]}
                      </div>
                    </div>
                    <div className="mt-2 flex flex-row w-full m-0 p-0">
                      <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                        Max Drawdown &nbsp;
                      </div>
                      <div className="text-right font-sans text-sm sm:text-lg tracking-wider font-medium text-red-600">
                        {item["Max Drawdown"]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center mt-20 ">
      {navigate ? (
        <div>
          {console.log("navigating to : ", navigate)}
          <Navigate to={navigate} state={{ portfolioId: portfolioId }} />
        </div>
      ) : (
        // <div className="w-24 sm:w-[20rem] flex items-center justify-center ">
        //   {topPortfolioResponse ? (
        //     <div className="flex flex-col text-center w-screen">
        //       <h2 className="font-serif text-4xl mb-4  tracking-normal">
        //         Trending Portfolios
        //       </h2>
        //       {mainUI()}
        //     </div>
        //   ) : (
        //     <div className="w-screen h-40 flex items-center justify-center">
        //       <Spinner
        //         color="purple"
        //         aria-label="Extra small spinner example"
        //         size="xl"
        //       />
        //     </div>
        //   )}
        // </div>

        <div className="w-full sm:w-11/12  p-4">
          <div className=" flex flex-col text-center">
            <h2 className="font-serif text-4xl mb-4  tracking-normal">
              Top Portfolios Today
            </h2>
          </div>
          {topPortfolioResponse ? (
            <div className=" mt-10 flex items-center justify-center ml-0 mr-0">
              <div className="flex overflow-x-auto space-x-4 p-0 bg-violet-100 border border-blue-400 rounded-lg">
                {topPortfolioResponse.map((item, index) => (
                  <div
                    key={index}
                    className="flex-shrink-0  m-4 bg-gray-100 border border-blue-100 rounded-lg text-center"
                    onClick={() => openPortfolio(item["id"])}
                  >
                    <div className="block max-w-sm p-4 h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      {/* {item[0]} */}
                      <div className="flex flex-col m-0 p-0 items-center w-52  h-full ">
                        <Avatar name={item["name"]} size="100" round />

                        <div className="text-xl font-sans font-semibold mt-4 ">
                          <p className="line-clamp-2 min-h-[4rem]">
                            {item["name"]}
                          </p>
                        </div>

                        <div className="w-full h-0.5 bg-orange-500 mb-2 mt-2"></div>

                        <div className=" w-full m-0 p-2 sm:p-0 ">
                          <div className="mt-0 flex flex-row w-full m-0 p-0 items-center">
                            <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                              Today's Return &nbsp;
                            </div>
                            <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                              {item["ret"]}
                            </div>
                          </div>
                          <div className="mt-2 flex flex-row w-full m-0 p-0">
                            <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                              CAGR &nbsp;
                            </div>
                            <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                              {item["CAGR"]}
                            </div>
                          </div>
                          <div className="mt-2 flex flex-row w-full m-0 p-0">
                            <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                              Max Drawdown &nbsp;
                            </div>
                            <div className="text-right font-sans text-sm sm:text-lg tracking-wider font-medium text-red-600">
                              {item["Max Drawdown"]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            showSpiner()
          )}
        </div>
      )}
    </div>
  );
}
