import React, { Component, useRef, useState } from "react";
import { MenuItem, Menu } from "semantic-ui-react";
import Constants from "../../utils/wpiconsts";
import { NavLink, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function TopNavBarNew() {
  const [state, setState] = useState(Constants.MenuStatus.Home.name);
  const [url, setUrl] = useState(null);

  // const handleItemClick = (e, { name, link }) => {
  //   console.log("handleItemClick : ", name, " link : ", link);
  //   setState(name);
  //   setUrl(link);
  //   // <Navigate to={link} />;
  // };

  const redirect = (name, url) => {
    console.log("redirect : ", url);
    <Navigate to={url} />;
    setState(name);
    setUrl(null);
  };
  const logoRef = useRef(null);

  const isUserLoggedIn = useSelector((state) => {
    console.log("TopNavBarNew state auth is : ", state.auth.isLoggedIn);
    return state.auth.isLoggedIn;
  });

  console.log(
    "TopNavBar :: isUserLoggedIn :: ",
    isUserLoggedIn,
    " state is : ",
    state
  );

  return (
    <div>{url ? redirect(url) : <></>}</div> && (
      <div className="h-24 ">
        <Menu
          inverted
          stackable={false}
          compact={true}
          fluid={true}
          floated={true}
          vertical={false}
          className="w-full h-24 sm:h-16 "
        >
          <MenuItem header>
            <div className=" w-14 h-14 " ref={logoRef}>
              <img className=" m-0" src="wealth_pulse_logo.png" />
            </div>
          </MenuItem>
          <MenuItem
            className="text-sm sm:text-lg font-extrabold font-sans tracking-widest bg-white"
            position="right"
            name={Constants.MenuStatus.Home.name}
            link={Constants.MenuStatus.Home.url}
            as={NavLink}
            to={Constants.MenuStatus.Home.url}
            active={url === Constants.MenuStatus.Home.url}
          />
          <MenuItem
            className="text-sm sm:text-lg font-semibold sm:font-extrabold font-sans tracking-tight sm:tracking-widest bg-white"
            name={Constants.MenuStatus.Create.name}
            link={Constants.MenuStatus.Create.url}
            as={NavLink}
            to={Constants.MenuStatus.Create.url}
            active={url === Constants.MenuStatus.Create.url}
          />
          <MenuItem
            className="text-sm sm:text-lg font-semibold sm:font-extrabold font-sans tracking-tight sm:tracking-widest bg-white"
            name={Constants.MenuStatus.Analyse.name}
            link={Constants.MenuStatus.Analyse.url}
            as={NavLink}
            to={Constants.MenuStatus.Analyse.url}
            active={url === Constants.MenuStatus.Analyse.url}
          />

          <MenuItem
            className="text-sm sm:text-lg font-semibold sm:font-extrabold font-sans tracking-tight sm:tracking-widest bg-white"
            position="right"
            name={
              !isUserLoggedIn
                ? Constants.MenuStatus.Login.name
                : Constants.MenuStatus.Logout.name
            }
            link={
              !isUserLoggedIn
                ? Constants.MenuStatus.Login.url
                : Constants.MenuStatus.Logout.url
            }
            as={NavLink}
            to={
              !isUserLoggedIn
                ? Constants.MenuStatus.Login.url
                : Constants.MenuStatus.Logout.url
            }
            active={
              url ===
              (!isUserLoggedIn
                ? Constants.MenuStatus.Login.url
                : Constants.MenuStatus.Logout.url)
            }
          />
        </Menu>
      </div>
    )
  );
}
