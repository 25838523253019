import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WpiConstants from "../utils/wpiconstants";

const initialState = {
  idx: [],
  portfolioOhlc: [],
  portfolioCloseArr: [],
  meta: {},
  info: null,
  dailyRet: [],
  monthlyRet: [],
  yearlyRet: [],
  sma: [],
  error: "",
};

export const portfolioDataSlice = createSlice({
  name: "portfolioData",
  initialState,
  reducers: {
    resetData: (state) => {
      state.idx = [];
      state.portfolioOhlc = [];
      state.portfolioCloseArr = [];
      state.meta = {};
      state.info = {};
      state.dailyRet = [];
      state.monthlyRet = [];
      state.yearlyRet = [];
      state.sma = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioData.pending, (state) => {
        console.log("Portfolio Slice : pending case : ");
        state.loading = true;
      })
      .addCase(fetchPortfolioData.fulfilled, (state, action) => {
        console.log("Portfolio Slice : fulfilled case : ", action);

        state.idx = action.payload["idx"];
        state.portfolioOhlc = action.payload["data"];
        state.portfolioCloseArr = action.payload["portfolio"];
        state.meta = action.payload["meta"];
        state.info = action.payload["info"];
        state.dailyRet = action.payload["daily_ret"];
        state.monthlyRet = action.payload["monthly_ret"];
        state.yearlyRet = action.payload["yearly_ret"];
        state.sma = action.payload["sma"];
        state.loading = false;
      })
      .addCase(fetchPortfolioData.rejected, (state, action) => {
        console.log("Portfolio Slice : rejected case : ", action);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const fetchPortfolioData = createAsyncThunk(
  "fetch/portfolio",
  async (portfolioId) => {
    const url = WpiConstants.GET_PORTFOLIO_INDEX_URL;
    const requestBody = {
      hist_start_dt: "2023-01-01",
      portfolio_id: portfolioId,
      is_force: false,
    };
    console.log(
      "making api call for fetchPortfolioData for id : ",
      portfolioId
    );
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log("fetchPortfolioData response is : ", response);
    const json_data = await response.json();
    console.log("json data is : ", json_data);
    return json_data;
  }
);

// Action creators are generated for each case reducer function
export const { resetData } = portfolioDataSlice.actions;

export default portfolioDataSlice.reducer;
