import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
  return (
    // <Wrapper id="services">
    //   {/* <div className="lightBg" style={{ padding: "50px 0" }}>
    //     <div className="container">
    //       <ClientSlider />
    //     </div>
    //   </div> */}
    //   <div className="lightBg" style={{ padding: "60px 0" }}>
    //     <div className="container">
    //       <HeaderInfo>
    //         <h2 className="font40 extraBold">What you can do</h2>
    //         <p className="font13">
    //           Users can create their own portfolio based on their own research
    //           and understanding.
    //           <br />
    //           They can also track the performance of their portfolio and also
    //           check the top performing portfolios
    //         </p>
    //       </HeaderInfo>
    //       <ServiceBoxRow className="flex">
    //         <ServiceBoxWrapper>
    //           <ServiceBox
    //             icon="roller"
    //             title="Create Portfolio"
    //             subtitle="Create portfolio using more than 1000 of stocks across different sectors"
    //           />
    //         </ServiceBoxWrapper>
    //         <ServiceBoxWrapper>
    //           <ServiceBox
    //             icon="monitor"
    //             title="Analyse Performance"
    //             subtitle="Check the various metrics of your portfolio to help evaluate its performance"
    //           />
    //         </ServiceBoxWrapper>
    //         <ServiceBoxWrapper>
    //           <ServiceBox
    //             icon="browser"
    //             title="Historical Performance"
    //             subtitle="Check the historical performance of your portfolio graphically"
    //           />
    //         </ServiceBoxWrapper>
    //         <ServiceBoxWrapper>
    //           <ServiceBox
    //             icon="monitor"
    //             title="Watch"
    //             subtitle="Watch the publically created popular portfolios"
    //           />
    //         </ServiceBoxWrapper>
    //       </ServiceBoxRow>
    //     </div>
    //     {/* <div className="lightBg">
    //       <div className="container">
    //         <Advertising className="flexSpaceCenter">
    //           <AddLeft>
    //             <h4 className="font15 semiBold">A few words about company</h4>
    //             <h2 className="font40 extraBold">A Study of Creativity</h2>
    //             <p className="font12">
    //               Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
    //               diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
    //             </p>
    //             <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
    //               <div style={{ width: "190px" }}>
    //                 <FullButton title="Get Started" action={() => alert("clicked")} />
    //               </div>
    //               <div style={{ width: "190px", marginLeft: "15px" }}>
    //                 <FullButton title="Contact Us" action={() => alert("clicked")} border />
    //               </div>
    //             </ButtonsRow>
    //           </AddLeft>
    //           <AddRight>
    //             <AddRightInner>
    //               <div className="flexNullCenter">
    //                 <AddImgWrapp1 className="flexCenter">
    //                   <img src={AddImage1} alt="office" />
    //                 </AddImgWrapp1>
    //                 <AddImgWrapp2>
    //                   <img src={AddImage2} alt="office" />
    //                 </AddImgWrapp2>
    //               </div>
    //               <div className="flexNullCenter">
    //                 <AddImgWrapp3>
    //                   <img src={AddImage3} alt="office" />
    //                 </AddImgWrapp3>
    //                 <AddImgWrapp4>
    //                   <img src={AddImage4} alt="office" />
    //                 </AddImgWrapp4>
    //               </div>
    //             </AddRightInner>
    //           </AddRight>
    //         </Advertising>
    //       </div>
    //     </div> */}
    //   </div>
    // </Wrapper>

    <div className="bg-green-300 mt-10 mb-10 pt-0 pb-0 flex flex-row">
      <div className="w-1/2 bg-red-500 flex items-center ">
        <div className="w-full sm:w-2/3">
          <h2 className="pl-4 sm:pl-20 text-3xl text-white font-sans text-left font-semibold">
            Evaluate and Analyse your Portfolio
          </h2>
          <div className="pl-4 sm:pl-20 pt-5 text-gray-100 font-sans font-medium text-lg">
            Create, track and analyse the performance of your multiple
            portfolios
          </div>
        </div>
      </div>
      <div className="w-1/2 bg-white ">
        <img src="bg_img.png" className="w-full h-[24rem] sm:h-[32rem]"></img>
      </div>
    </div>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
