import React, { useEffect, useState } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

import { getCurrentUser } from "aws-amplify/auth";
import { Navigate } from "react-router-dom";
import TopNavbarNew from "../components/Nav/TopNavBarNew";
import TopTrendingPortfolio from "../components/Sections/Portfolio/TopTrendingPortfolio";
import TopTrendingPortfoliosForDay from "../components/Sections/Portfolio/TopTrendingPortfoliosForDay";

export default function Landing({ loggedIn }) {
  // console.log("Landing : ", loggedIn);

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     try {
  //       const user = await getCurrentUser();
  //       setIsLoggedIn(true);
  //     } catch (error) {
  //       setIsLoggedIn(false);
  //       console.error('Error checking authentication state:', error);
  //     }
  //   };

  //   checkAuth();
  // }, []);

  const onLanding = () => {
    console.log("Landing Page ");
    return (
      <div>
        <TopNavbarNew />
        {/* <Header /> */}
        <div>
          <Services />
          <TopTrendingPortfolio />
          <TopTrendingPortfoliosForDay />
          {/* <Projects /> */}
          {/* <Blog /> */}
          {/* <Pricing /> */}
          {/* <Contact /> */}
          <Footer />
        </div>
      </div>
    );
  };

  // return (
  //   <div>
  //   {isLoggedIn ?  <Navigate to="/home" /> : onLoggedOut() }
  //   </div>
  // );

  return onLanding();
}
