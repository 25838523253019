import React, { useEffect, useState } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

import { getCurrentUser } from "aws-amplify/auth";
import { Navigate } from "react-router-dom";
import LoggedInLanding from "./LoggedInLanding";

import Landing from "./Landing";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

import { useSelector, useDispatch } from "react-redux";
import { markLoggedIn } from "../redux/authslice";
import TopNavBarNew from "../components/Nav/TopNavBarNew";

export default function AppLogin({ loggedIn }) {
  const isUserLoggedIn = useSelector((state) => {
    console.log("AppLogin : state.auth is : ", state.auth);
    return state.auth.isLoggedIn;
  });

  console.log("AppLogin : ", isUserLoggedIn);

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await getCurrentUser();
        console.log("user is : ", user);
        // setIsLoggedIn(true);
      } catch (error) {
        // setIsLoggedIn(false);
        console.error("Error checking authentication state:", error);
      }
    };

    checkAuth();
  }, []);

  const onLoggedOut = () => {
    // console.log("user not logged in ");
    return (
      <>
        <TopNavbar />
        <Header />
        <Services />
        <Projects />
        {/* <Blog /> */}
        {/* <Pricing /> */}
        {/* <Contact /> */}
        <Footer />
      </>
    );
  };

  return (
    <div>
      <TopNavBarNew />
      <section>
        <div class=" h-screen flex items-center justify-center">
          <Authenticator.Provider>
            <LoginCallback />
            <Authenticator />
          </Authenticator.Provider>
        </div>
      </section>
    </div>
  );
}

const LoginCallback = () => {
  const { route, user } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);

  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  React.useEffect(() => {
    console.log("AppLogin :: ", route);

    if (route === "authenticated") {
      console.log("Login successful", user);
      dispatch(markLoggedIn());
      setIsLoggedIn(true);
    }
  }, [route, user]);

  return <div>{isLoggedIn ? <Navigate to="/" /> : <div />}</div>;

  return null; // This component doesn't render anything visible
};
