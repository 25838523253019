import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";

import { useSelector, useDispatch } from "react-redux";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const isUserLoggedIn = useSelector((state) => {
    // console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
    return state.auth.isLoggedIn;
  });

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          <h2 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            WealthPulse
          </h2>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      {/* <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Home
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Services
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Projects
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Blog
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Pricing
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact
          </Link>
        </li>
      </UlStyle> */}
      <UlStyle className="flex-col">
        <>
          <li className="semiBold font15 pointer text-lg">
            <Link
              activeClass="active"
              to="/"
              spy={true}
              smooth={true}
              offset={-80}
              className="text-white"
            >
              Explore
            </Link>
          </li>
          <li className="semiBold font15 pointer text-lg">
            <Link
              activeClass="active"
              to="/"
              spy={true}
              smooth={true}
              offset={-80}
              className="text-white"
            >
              Create
            </Link>
          </li>

          <li className="semiBold font15 pointer text-lg">
            <Link
              activeClass="active"
              to="/portfolio/sector"
              spy={true}
              smooth={true}
              offset={-80}
              className="text-white"
            >
              Analyse
            </Link>
          </li>

          <li className="semiBold font15 pointer text-lg">
            {isUserLoggedIn ? (
              <a
                href="/logout"
                style={{ padding: "10px 30px 10px 0" }}
                className="whiteColor"
              >
                Log Out
              </a>
            ) : (
              <a
                href="/login"
                style={{ padding: "10px 30px 10px 0" }}
                className="whiteColor"
              >
                Log in
              </a>
            )}
          </li>
        </>

        {/* <li className="semiBold font15 pointer flexCenter">
          <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Get Started
          </a>
        </li> */}
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
