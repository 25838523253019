import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WpiConstants from "../utils/wpiconstants";

const initialState = {
  data: {},
  loading: true,
  error: "",
};

export const sectorDataSlice = createSlice({
  name: "sectorDataData",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSectorData.pending, (state) => {
        console.log("Sector Slice : pending case : ");
        state.loading = true;
      })
      .addCase(fetchSectorData.fulfilled, (state, action) => {
        console.log("Sector Slice : fulfilled case : ", action);

        state.data = action.payload;
        state.loading = false;
        console.log("sector state : ", state.data);
      })
      .addCase(fetchSectorData.rejected, (state, action) => {
        console.log("Sector Slice : rejected case : ", action);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const fetchSectorData = createAsyncThunk("fetch/portfolio", async () => {
  const url = WpiConstants.GET_SECTOR_INFO_URL;
  const requestBody = {};
  console.log("making api call for fetchSector ");
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-type": "application/json",
    },
  });
  console.log("fetchPortfolioData response is : ", response);
  const json_data = await response.json();
  console.log("json data is : ", json_data);
  return json_data;
});

// Action creators are generated for each case reducer function
export const { resetData } = sectorDataSlice.actions;

export default sectorDataSlice.reducer;
