import _ from "lodash";

import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";

import {
  Button,
  Search,
  Header,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Checkbox,
  Input,
  Modal,
  Icon,
  ModalContent,
  ModalActions,
  Message,
} from "semantic-ui-react";
import { useSelector } from "react-redux";

import { createChart, ColorType, LineType } from "lightweight-charts";

export const PortfolioChart = (props) => {
  // const [updateOhlc, setUpdateOhlc] = useEffect(true)

  const idx_container = useRef(null);
  const ohlc_parent_ref = useRef(null);

  const ohlc_legend_ref = useRef(null);

  const [legendOpen, setLegendOpen] = useState(null);
  const [legendHigh, setLegendHigh] = useState(null);
  const [legendLow, setLegendLow] = useState(null);
  const [legendClose, setLegendClose] = useState(null);
  const [legendSMA, setLegendSMA] = useState(null);

  // console.log("PortfolioChart props is : ", props);
  const {
    idx,
    portfolioIdx,
    portfolioOhlc,
    smaData,
    tableRef,
    dimen: { chart_height = 500 } = {},
    colors: {
      backgroundColor = "white",
      lineColor = "#2962FF",
      portfolio_lineColor = "rgba(128, 200, 200, 255)",
      textColor = "black",
      // areaTopColor = '#2962FF',
      areaTopColor = "rgba(2555, 255, 255, 0)",
      // areaBottomColor = 'rgba(41, 98, 255, 0.28)',
      areaBottomColor = "rgba(2555, 255, 255, 0)",
    } = {},
  } = props;

  // setUpdateOhlc(true);

  useEffect(() => {
    if (portfolioOhlc == null || smaData == null) {
      return;
    }
    const container = document.getElementById("ohlc_container");
    const chartOptions = {
      layout: {
        background: { type: ColorType.Solid, color: "white" },
        textColor,
      },
      width: ohlc_parent_ref.current.clientWidth,
      height: chart_height,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
    };
    const chart = createChart(container, chartOptions);

    // const idxSeries = chart.addLineSeries({ color: "#2962FF" });
    // idxSeries.setData(idx);

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    const data_arr = portfolioOhlc;
    const handleResize = () => {
      chart.applyOptions({ width: idx_container.current.clientWidth });
      // chart.timeScale().fitContent();
    };
    console.log("ohlc data is : ", data_arr);
    candlestickSeries.setData(data_arr);
    window.addEventListener("resize", handleResize);
    const smaDataSeries = chart.addLineSeries({ color: "rgb(225, 87, 90)" });
    smaDataSeries.setData(smaData);

    chart.timeScale().fitContent();
    chart.subscribeCrosshairMove((param) => {
      let priceFormatted = "";
      console.log("param : ", param);
      if (param.time) {
        param.seriesData.forEach((data) => {
          // ohlc_legend_ref.current.value = "Hello";
          // console.log(
          //   "ohlc : ",
          //   val,
          //   " set text : ",
          //   ohlc_legend_ref.current.value,
          //   " id text : ",
          //   document.getElementById("ohlc_legend").innerHTML
          // );

          console.log("pointer data : ", data);
          if (data["open"]) {
            setLegendOpen(data["open"]);
            setLegendHigh(data["high"]);
            setLegendLow(data["low"]);
            setLegendClose(data["close"]);
          } else if (data["value"]) {
            setLegendSMA(data["value"]);
          }

          const legendContainer = document.getElementById("ohlc_legend");

          // const legend = document.createElement("div");
          //   legend.style = `position: absolute; left: 12px; top: 12px; z-index: 1; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 300;`;
          // legend.style.fontWeight = "bold";
          // legend.innerHTML = `<div> ${ohlc_legend_ref.current.value} </div>`;
          // legendContainer.appendChild(legend);
        });
        // const dataPoint = param.seriesData.get(areaSeries);
        // const price = data.value !== undefined ? data.value : data.close;
        // priceFormatted = price.toFixed(2);
      }
      // legend is a html element which has already been created
      // legend.innerHTML = `${symbolName} <strong>${priceFormatted}</strong>`;
    });
    console.log("ohlc rendering done");

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [portfolioOhlc]);

  return (
    <div className="w-full flex flex-col" ref={idx_container}>
      {/* <div
        className=" m-4 bg-green-800 border border-black"
        id="idx_comparision"
        ref={idx_container}
      ></div> */}
      <div className="p-2 flex flex-row ">
        {legendOpen ? (
          <div>
            Open <div className="text-sm mr-2 mt-2"> {legendOpen} </div>
          </div>
        ) : (
          <div></div>
        )}
        {legendHigh ? (
          <div>
            High <div className="text-sm mr-2 mt-2"> {legendHigh} </div>
          </div>
        ) : (
          <div></div>
        )}
        {legendLow ? (
          <div>
            Low <div className="text-sm mr-2 mt-2"> {legendLow} </div>
          </div>
        ) : (
          <div></div>
        )}
        {legendClose ? (
          <div>
            Close <div className="text-sm mr-2 mt-2"> {legendClose} </div>
          </div>
        ) : (
          <div></div>
        )}
        {legendSMA ? (
          <div>
            SMA <div className="text-sm mr-2 mt-2"> {legendSMA} </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div
        className=" mt-0 bg-green-800 border border-black"
        id="ohlc_container"
        ref={ohlc_parent_ref}
      ></div>
    </div>
  );
};

export default PortfolioChart;
