import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const bottomUI = () => {
    return (
      <div className="flex flex-1 justify-end items-end bg-green-500 ">
        <StyleP className="text-slate-200 text-xs tracking-wide">
          © {getCurrentYear()} -{" "}
          <span className="text-3xl font-serif text-white">Wealth Pulse</span>
          &nbsp; All Right Reserved
        </StyleP>
      </div>
    );
  };

  return (
    <div className="bg-orange-600 h-24 flex flex-row items-center p-2 sm:p-6 justify-between">
      <div className=" w-14 h-14 flex-shrink-0">
        <img className=" m-0" src="wealth_pulse_logo.png" />
      </div>

      {/* <div className="w-10 bg-yellow-500" /> */}

      <div className="flex flex-col sm:flex-row  text-center  flex-shrink-0">
        <StyleP className="text-slate-200  justify-between text-xs tracking-wide ">
          © {getCurrentYear()} -{" "}
          <span className="text-3xl font-serif text-white">Wealth Pulse</span>
          <div> &nbsp; All Right Reserved </div>
        </StyleP>
      </div>
    </div>
  );
  // return (
  //   <Wrapper>
  //     <div className="darkBg">
  //       <div className="container">
  //         <InnerWrapper
  //           className="flexSpaceCenter"
  //           style={{ padding: "30px 0" }}
  //         >
  //           <Link
  //             className="flexCenter animate pointer"
  //             to="home"
  //             smooth={true}
  //             offset={-80}
  //           >
  //             <LogoImg />
  //             <h2
  //               className="font15 extraBold whiteColor"
  //               style={{ marginLeft: "15px" }}
  //             >
  //               Wealth Pulse
  //             </h2>
  //           </Link>
  //           <StyleP className="whiteColor font13">
  //             © {getCurrentYear()} -{" "}
  //             <span className="purpleColor font13">Wealth Pulse</span> All Right
  //             Reserved
  //           </StyleP>

  //           {/* <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
  //             Back to top
  //           </Link> */}
  //         </InnerWrapper>
  //       </div>
  //     </div>
  //   </Wrapper>
  // );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
