// Routes.js
import React from "react";
import AppLanding from "./screens/AppLanding";
import PortfolioCreate from "./screens/PortfolioCreate";
import NotFound from "./screens/NotFound";
import LoggedInLanding from "./screens/LoggedInLanding";
import AppLogin from "./screens/AppLogin";
import AppLogout from "./screens/AppLogout";
import { PortfolioLanding } from "./components/Sections/Portfolio/PortfolioLanding";
import { PortfolioSector } from "./components/Sections/Portfolio/PortfolioSector";

const AppRoutes = [
  {
    path: "/",
    element: <AppLanding />,
    errorElement: <NotFound />,
  },
  {
    path: "/login",
    element: <AppLogin />,
  },
  {
    path: "/logout",
    element: <AppLogout />,
  },
  {
    path: "/portfolio/create",
    element: <PortfolioCreate />,
  },
  {
    path: "/portfolio/landing",
    element: <PortfolioLanding />,
  },
  {
    path: "/portfolio/sector",
    element: <PortfolioSector />,
  },
];

export default AppRoutes;
