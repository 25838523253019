import _ from "lodash";

import React, { useState, useEffect } from "react";

import styled from "styled-components";

import {
  Button,
  Search,
  Header,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Checkbox,
  Input,
  Modal,
  Icon,
  ModalContent,
  ModalActions,
  Message,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import PortfolioChart from "./PortfolioChart";
import WpiConstants from "../../../utils/wpiconstants";

// Components

export default function PCreate() {
  const [apiListData, setApiListData] = useState([]);
  const [searchState, setSearchState] = useState({
    loading: false,
    value: "",
    results: [],
  });

  const [showTable, setShowTable] = useState(false);
  const [userSelectedStocks, setUserSelectedStocks] = useState([]);

  const [removeSelectedStocks, setRemoveSelectedStocks] = useState([]);

  const [totalWeight, setTotalWeight] = useState([]);

  const [portfolioName, setPortfolioName] = useState("");

  const [isCreateReady, setIsCreateReady] = useState(false);

  const [createButtonLoading, setCreateButtonLoading] = useState(false);

  const [createActionMsg, setCreateActionMsg] = useState("");

  const [redirectToChart, setRedirectToChart] = useState(false);

  const [componentArr, setComponentArr] = useState([]);

  const [idxRes, setIdxRes] = useState([]);

  const [isValidQty, setIsValidQty] = useState(false);

  const [ltpDict, setLtpDict] = useState({});

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        console.log("calling fetchdata");
        const url = WpiConstants.GET_TICKER_LIST_API_GW;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        console.log("jsonData is : ", jsonData);
        // const jsonBody = JSON.parse(jsonData.data);
        console.log("fetch data response is : ", jsonData["data"]);
        console.log(" 1 : ", jsonData.data);
        console.log(" 2 : ", jsonData.data);
        console.log(" 3 : ", jsonData["data"]);
        // console.log(" 4 : ", typeof jsonBody);
        // console.log(" 4 : ", typeof jsonBody.data);
        setApiListData(jsonData.data); // Set the fetched data to the state
        setTotalWeight(0);

        console.log("fetch data after set to setApiListData,  : ", apiListData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  useEffect(() => {
    // Calculate total value whenever rows data changes
    let newTotal = 0;
    console.log("useEffect for userSelectedStocks : ", userSelectedStocks);
    for (let i = 0; i < userSelectedStocks.length; i++) {
      console.log("row : ", userSelectedStocks[i]);
      newTotal += userSelectedStocks[i][2];
    }
    console.log(
      "setting total weight : ",
      newTotal,
      " weight before : ",
      totalWeight
    );
    setTotalWeight(newTotal);

    // if(newTotal == 100 && portfolioName.length > 0) {
    //   console.log("newTotal Weight is : ", newTotal, " portfolio name  is : ", portfolioName, " enabling create button");
    //   setIsCreateReady(true)
    // } else {
    //   setIsCreateReady(false)
    //   console.log("newTotal Weight is : ", newTotal, " portfolio name  is : ", portfolioName, " disabling create button");
    // }

    if (portfolioName.length > 0 && isValidQty) {
      setIsCreateReady(true);
    } else {
      setIsCreateReady(false);
    }
  }, [userSelectedStocks]);

  useEffect(() => {
    // if(totalWeight == 100 && portfolioName.length > 0) {
    //   setIsCreateReady(true)
    //   console.log("totalWeight is : ", totalWeight, " portfolio name  is : ", portfolioName, " enabling create button");
    // } else {
    //   setIsCreateReady(false)
    //   console.log("totalWeight is : ", totalWeight, " portfolio name  is : ", portfolioName, " disabling create button");
    // }

    if (portfolioName.length > 0 && isValidQty) {
      setIsCreateReady(true);
    } else {
      setIsCreateReady(false);
    }
  }, [portfolioName]);

  const makeLtpApi = async (ticker) => {
    try {
      console.log(
        "makeLtpApi called for ticker ",
        ticker,
        " ltpDict is : ",
        ltpDict
      );
      const requestBody = { ticker_list: [ticker] };
      console.log("request body : ", requestBody);
      const url = WpiConstants.GET_LTP_URL;
      let ltp = 0;
      if (ltpDict[ticker]) {
        ltp = ltpDict[ticker];
      } else {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        // Handle the response

        const { data } = await response.json();
        console.log("API response: ", data);
        ltp = Object.values(data)[0];
        ltpDict[ticker] = ltp;
        setLtpDict(ltpDict);
        console.log("updated ltp dict : ", ltpDict);
      }
      console.log(" ltp : ", ltp);
      const updatedStocks = [...userSelectedStocks];

      let idx_arr = [];
      console.log("ticker is : ", ticker);
      userSelectedStocks.map((item, idx) => {
        console.log("idx : ", idx, " item : ", item);
        if (item[0] == ticker) {
          // idx_arr.push(idx)
          console.log("found match, updatint item");
          updatedStocks[idx][1] = ltp;
        }
      });
      console.log("updating userSelectedStocks : ", updatedStocks);
      setUserSelectedStocks(updatedStocks);

      const rowIndex = userSelectedStocks.findIndex(
        (item) => item[0] === ticker
      );
      // if (rowIndex !== -1) {
      //   const updatedStocks = [...userSelectedStocks];
      //   updatedStocks[rowIndex][1] = ltp;
      //   setUserSelectedStocks(updatedStocks);
      // }

      // updatedStocks.map (item => {
      //   if (item[0] == ticker) {
      //     console.log("updating price for ticker ", ticker);
      //     var newItem = item;
      //     newItem[1] = Object.values(data)[0];
      //     return{
      //       newItem
      //     }
      //     // item[1] = Object.values(data)[0]
      //   }
      //   return item;
      // })
      // console.log("modified userSelectedStocks is : \n", userSelectedStocks);
      // setUserSelectedStocks(userSelectedStocks)
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleSearchChange = (event, uiItem) => {
    console.log(
      "handleSearchChange : ",
      event.target.value,
      " input is : ",
      uiItem.value,
      " data is : ",
      apiListData
    );
    if (apiListData == null) {
      return;
    }
    // setState([true, event.target.value, []])
    setSearchState((prevState) => ({
      ...prevState,
      loading: true,
      value: uiItem.value,
    }));
    // setTimeout(() => {
    var filteredData = [];
    var filteredDataTmp = [];
    if (uiItem.value !== "") {
      console.log(
        "item.value  : ",
        uiItem.value,
        " : ",
        uiItem.value.toLowerCase()
      );
      // console.log("apiListData : ", apiListData);
      filteredDataTmp = apiListData.filter((item) =>
        item.toLowerCase().startsWith(uiItem.value.toLowerCase())
      );
      filteredDataTmp.map((item) => filteredData.push({ item }));
    }
    // setState([false, event.target.value, filteredData])
    console.log(
      "setting filtered response : ",
      filteredData,
      " : ",
      uiItem.value
    );
    setTimeout(() => {
      setSearchState((prevState) => ({
        ...prevState,
        loading: false,
        results: filteredData,
      }));
    });
    // });
  };

  const searchResultRender = ({ item }) => {
    // console.log("serachResultRender : ", item);
    return <Header as={"h4"}> {item} </Header>;
  };

  const handleResultSelect = (event, data) => {
    console.log("handleResultSelect : ", data.result);
    setSearchState((prevState) => ({
      ...prevState,
      value: data.result.item,
    }));
    var ticker = data.result.item;
    let currUserSelectedStocks = userSelectedStocks;
    console.log("curr user selected stocks list is : ", currUserSelectedStocks);
    currUserSelectedStocks.unshift([ticker, 0, 0, true]);
    console.log(
      "after modifying, user selected stocks list is : ",
      currUserSelectedStocks
    );
    if (currUserSelectedStocks.length > 0) {
      setShowTable(true);
    }
    setUserSelectedStocks(currUserSelectedStocks);
    makeLtpApi(ticker);
  };

  const renderSelectedStocksAsTable = () => {
    return <div>{showTable && renderTableContent()}</div>;
  };

  const onStockRowChange = (event, data) => {
    const { value, checked } = data;
    console.log(
      "onStockRowChange : ",
      value,
      " : ",
      checked,
      " removelist : ",
      removeSelectedStocks
    );
    // console.log("event : ", event,  " data : ", data);
    var currRemoveStockList = removeSelectedStocks;
    if (checked) {
      if (!currRemoveStockList.includes(value)) {
        currRemoveStockList.push(value);
        console.log("added to currRemoveStockList : ", currRemoveStockList);
      }
    } else {
      if (currRemoveStockList.includes(value)) {
        currRemoveStockList = currRemoveStockList.filter(
          (ticker) => ticker !== value
        );
        console.log("removed from currRemoveStockList : ", currRemoveStockList);
      }
    }

    setRemoveSelectedStocks(currRemoveStockList);
    console.log(
      "remove list is  : ",
      removeSelectedStocks,
      " curr remove list : ",
      currRemoveStockList
    );
  };

  const userName = useSelector((state) => {
    // console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
    return state.auth.user;
  });

  const [showModal, setShowModal] = useState(false);
  const [createError, setCreateError] = useState([]);

  const openModal = () => {
    return (
      <Modal
        basic
        onClose={() => setShowModal(false)}
        onOpen={() => setShowModal(true)}
        open={showModal}
        size="small"
        trigger={<Button>Alert</Button>}
      >
        <Header icon>
          <Icon name="archive" />
          Error in saving Portfolio
        </Header>
        <ModalContent>
          <p>{createError}</p>
        </ModalContent>
        <ModalActions>
          <Button color="green" inverted onClick={() => setShowModal(false)}>
            <Icon name="checkmark" /> Ok
          </Button>
        </ModalActions>
      </Modal>
    );
  };

  const onCreateButtonClick = async () => {
    console.log("onCreateButtonClick : ", totalWeight);
    try {
      const component_arr = [];
      const username = userName;

      userSelectedStocks.forEach((item) => {
        component_arr.push({ ticker: item[0], ltp: item[1], qty: item[2] });
      });

      setComponentArr(component_arr);

      const requestBody = {
        name: portfolioName,
        user: username,
        components: component_arr,
      };
      const url = WpiConstants.PORTFOLIO_CREATE_URL;

      console.log("create button api body is : \n", requestBody);

      setCreateButtonLoading(true);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      console.log("api response : ", response);
      if (response.ok) {
        const res = await response.json();
        let portfolio_id = res["portfolio_id"];
        setCreateActionMsg("Portfolio Created Successfully!!");
        console.log(
          "Portfolio Created Successfully!! portfolio_id is : ",
          portfolio_id
        );
        setTimeout(() => {
          console.log("invoking fetch index api");
          setCreateActionMsg("");
          // fetch_portfolio_index_data(portfolio_id);
        }, 0);
      } else {
        const json_res = await response.text();

        console.log("Portfolio create error : ", json_res);
        // setCreateError(json_res["message"]);
        setCreateActionMsg(json_res);
        setCreateError(json_res);
        // setShowModal(true);
      }

      setCreateButtonLoading(false);
    } catch (error) {
      console.log("error in onCreate Button Click : ", error);
    }
  };

  const fetch_portfolio_index_data = async (portfolio_id) => {
    const portfolio_start_dt = "2024-04-19";
    const component_arr = [];
    userSelectedStocks.forEach((item) => {
      component_arr.push({ ticker: item[0], ltp: item[1], weight: item[2] });
    });

    const requestBody = {
      portfolio_start_dt: portfolio_start_dt,
      hist_start_dt: "2023-04-19",
      portfolio_id: portfolio_id,
    };
    const url = WpiConstants.GET_PORTFOLIO_INDEX_URL;

    console.log("create button api body is : \n", requestBody);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    console.log("api response : ", response);
    if (response.ok) {
      const json_res = await response.json();
      setIdxRes(json_res);
    } else {
      const json_res = await response.text();
      console.log("error in api response : ", json_res);
    }

    setTimeout(() => {
      console.log("displaying chart");
      setRedirectToChart(true);
    }, 1000);
  };

  const handleWeightInputChange = (selected_item, value) => {
    let currUserSelectedStocks = [...userSelectedStocks];
    currUserSelectedStocks.map((item) => {
      let weightSum =
        parseInt(totalWeight) + parseInt(value) - parseInt(selected_item[2]);
      if (item[0] === selected_item[0]) {
        item[2] = +value;
        if (item[2] === 0 || weightSum > 100 || weightSum < 100) {
          item[3] = true;
        } else {
          item[3] = false;
        }
        return item;
      }

      item[3] = false;
      return item;
    });
    console.log(
      " old value : ",
      selected_item[2],
      " current val : ",
      value,
      " totalweight : ",
      totalWeight
    );

    setUserSelectedStocks(currUserSelectedStocks);
    return false;
  };

  const handleQtyInputChange = (selected_item, value) => {
    let validQty = true;
    let currUserSelectedStocks = [...userSelectedStocks];
    currUserSelectedStocks.map((item) => {
      if (item[0] === selected_item[0]) {
        item[2] = +value;
        console.log("item[2] is ", item[2], " ", item[2] === 0);
        if (item[2] === 0) {
          item[3] = true;
          validQty = false;
        } else {
          item[3] = false;
        }
        return item;
      }

      item[3] = false;
      return item;
    });

    setIsValidQty(validQty);

    // console.log(" old value : ", selected_item[2], " current val : ", value, " totalweight : ", totalWeight);
    // console.log("setting currUserSelectedStocks : ", currUserSelectedStocks);
    setUserSelectedStocks(currUserSelectedStocks);
    return false;
  };

  const renderTableContent = () => {
    // console.log("renderTableContent : ", userSelectedStocks);
    console.log("total weight is : ", totalWeight);
    console.log(
      "createActionMsg : ",
      createActionMsg,
      " length : ",
      createActionMsg.length
    );
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Table celled fluid unstackable>
          <TableHeader>
            <TableRow>
              <TableHeader />
              <TableHeaderCell>Stock</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
            </TableRow>
          </TableHeader>
          {userSelectedStocks.map((item) => {
            console.log("stock is : ", item);
            return (
              <TableRow key={item[0]}>
                <TableCell>
                  <Checkbox value={item[0]} onChange={onStockRowChange} />
                </TableCell>
                <TableCell>{item[0]}</TableCell>
                <TableCell>{item[1]}</TableCell>
                <MaxWidthTableCell>
                  <WeightInput
                    focus
                    // onChange={ (e, { value }) => handleWeightInputChange(item, value.length > 0 ? value: 0) }
                    onChange={(e, { value }) =>
                      handleQtyInputChange(item, e.target.value)
                    }
                    error={item[3]}
                  ></WeightInput>
                </MaxWidthTableCell>
              </TableRow>
            );
          })}
        </Table>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "470px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <NameInput
              placeholder="Enter Portfolio Name"
              onChange={handlePortfolioNameChange}
            />

            {/* <AmountInput placeholder="Enter Portfolio amount" /> */}
          </div>

          <PortfolioButtons className="flex" flexDirection="row">
            <PortfolioButton
              style={{ marginRight: "10px" }}
              loading={createButtonLoading}
              primary
              onClick={onCreateButtonClick}
              disabled={!isCreateReady}
            >
              {" "}
              Create{" "}
            </PortfolioButton>
            <PortfolioButton primary> Cancel </PortfolioButton>
          </PortfolioButtons>

          <Message
            visible={createActionMsg.length > 0}
            hidden={createActionMsg.length === 0}
          >
            <Header content={createActionMsg}></Header>
          </Message>
        </div>

        {/* {redirectToChart ? <PortfolioChart componentArr={componentArr} data={idxRes}/> : <div> </div>} */}
      </div>
    );
  };

  const initialData = [
    { time: "2018-12-22", value: 32.51 },
    { time: "2018-12-23", value: 31.11 },
    { time: "2018-12-24", value: 27.02 },
    { time: "2018-12-25", value: 27.32 },
    { time: "2018-12-26", value: 25.17 },
    { time: "2018-12-27", value: 28.89 },
    { time: "2018-12-28", value: 25.46 },
    { time: "2018-12-29", value: 23.92 },
    { time: "2018-12-30", value: 22.68 },
    { time: "2018-12-31", value: 22.67 },
  ];

  const handlePortfolioNameChange = (e, { value }) => {
    const name = e.target.value;
    console.log(
      "portfolio name : ",
      name,
      " is valid : ",
      name.trim() !== "",
      " total weight is : ",
      totalWeight
    );
    setPortfolioName(name);
  };

  // const renderCreateAndSubmitButton = () => {
  //   return (

  //   );
  // }

  return (
    <Wrapper id="portfolio" className="container">
      <div className="lightBg">
        <HeaderP>
          <StyledInput
            fluid
            loading={searchState.loading}
            placeholder="Search Stock"
            minCharacters={2}
            onResultSelect={handleResultSelect}
            noResultsMessage="No Matching Result"
            onSearchChange={_.debounce(handleSearchChange, 500, {
              leading: true,
            })}
            resultRenderer={searchResultRender}
            results={searchState.results}
            value={searchState.value}
          />
          {/* <SumbitWrapper className="flex">
                <Button primary>Add</Button>
              </SumbitWrapper> */}
        </HeaderP>

        {renderSelectedStocksAsTable()}

        {showModal ? openModal() : <div />}
      </div>
    </Wrapper>
  );
}

const StyledTable = styled(Table)`
  width: 100%; /* Ensures table fills the width */

  @media (min-width: 768px) {
    /* Styles for large screens */
    font-size: 16px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    /* Styles for medium screens */
    font-size: 14px;
  }

  @media (max-width: 479px) {
    /* Styles for small screens */
    font-size: 12px;
  }
`;

const WeightInput = styled(Input)`
  width: 100%;

  // color: #fff;

  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const MaxWidthTableCell = styled(Table.Cell)`
  width: 40px; /* Adjust the maximum width as needed */
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameInput = styled(Input)`
  width: 100%; /* Adjust the maximum width as needed */
  max-width: 470px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 960px) {
    max-width: 470px;
  }
`;

const AmountInput = styled(Input)`
  width: 100%; /* Adjust the maximum width as needed */
  max-width: 470px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 960px) {
    max-width: 470px;
  }
`;

const SumbitWrapper = styled.div`
  flex: 1;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 40%;
    margin-bottom: 50px;
  }
`;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  width: 80%;
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  margin: 0 auto;
  display: flex;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const StyledInput = styled(Search)`
  &&& {
    border: none; /* Add border */
    border-radius: 4px; /* Add border radius for rounded corners */
    height: 40px; /* Example height */
    flex: 1;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Arrange children vertically */

    input {
      flex: 1; /* Make input fill remaining space */
      padding: 12px; /* Add padding */
      font-size: 16px; /* Set font size */
      outline: none; /* Remove default outline */
    }
  }
`;

const PortfolioButtons = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  background-color: transparent;
  justify-content: space-between;
  max-width: 470px;
`;

const PortfolioButton = styled(Button)`
  margin: 20px;
  flex-grow: 1;
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
