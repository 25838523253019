import React, { useEffect, useState } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

import { getCurrentUser } from "aws-amplify/auth";
import { Navigate, useLocation } from "react-router-dom";
import LoggedInLanding from "./LoggedInLanding";

import Landing from "./Landing";

import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../redux/authslice";

export default function AppLanding({ loggedIn }) {
  const landing = useLocation();

  const isUserLoggedIn = useSelector((state) => {
    console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
    return state.auth.isLoggedIn;
  });
  const dispatch = useDispatch();

  console.log("AppLanding state from reducer is : ", isUserLoggedIn);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await getCurrentUser();
        console.log("user : ", user);
        console.log("username : ", user.username);
        dispatch(setUser(user.username));
      } catch (error) {
        console.error("Error checking authentication state:", error);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    console.log("AppLanding :: ", landing);
    if (landing != null && landing.state != null) {
      const loggedin = landing.state["loggedin"];
      // const id = "";
      console.log("loggedin is : ", loggedin);
      // const ret = dispatch(fetchPortfolioData(id));
    }
  });

  const onLoggedOut = () => {
    // console.log("user not logged in ");
    return (
      <>
        <TopNavbar />
        <Header />
        <Services />
        <Projects />
        {/* <Blog /> */}
        {/* <Pricing /> */}
        {/* <Contact /> */}
        <Footer />
      </>
    );
  };

  return <div>{isUserLoggedIn ? <LoggedInLanding /> : <Landing />}</div>;
}
