class Item {
  private name: string;
  private url: string;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
  }
}

export default class Constant {
  static readonly MenuStatus = {
    Home: new Item("Home", "/"),
    Create: new Item("Create", "/portfolio/create"),
    Analyse: new Item("Analyse", "/portfolio/sector"),
    Login: new Item("Login", "/login"),
    Logout: new Item("Logout", "/logout"),
  };
}
