import React from "react";
// Sections
import LoggedInTopNavbar from "../components/Nav/LoggedInTopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import LoggedInCreatePortfolio from "../components/Sections/LoggedInCreatePortfolio";
import TopNavBarNew from "../components/Nav/TopNavBarNew";

const uiComponent = () => {
  return (
    <div id="components" className="flex flex-col">
      <LoggedInTopNavbar />
      {/* <Header /> */}
      {/* <Services /> */}
      <div id="projects" style={{ marginTop: "auto" }}>
        {/* {<LoggedInCreatePortfolio />} */}
      </div>
      {/* <Projects /> */}
      {/* <Blog /> */}
      {/* <Pricing /> */}
      {/* <Contact /> */}
      <Footer />
      {<LoggedInCreatePortfolio />}

      <Footer />
    </div>
  );
};

const uiComponentNew = () => {
  return (
    <div id="components" style={{ display: "flex", flexDirection: "column" }}>
      <TopNavBarNew />
      <div style={{ marginTop: "80px" }}>{<LoggedInCreatePortfolio />}</div>
      <Footer />
    </div>
  );
};

export default function LoggedInLanding({ signOut, user }) {
  console.log("LoggedInLanding : ", user);
  return uiComponentNew();
}
