import React, { useEffect, useState } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

import { getCurrentUser } from "aws-amplify/auth";
import { Navigate } from "react-router-dom";
import LoggedInLanding from "./LoggedInLanding";

import Landing from "./Landing";
import { Authenticator } from "@aws-amplify/ui-react";
import { signOut } from "@aws-amplify/auth";

import { useSelector, useDispatch } from "react-redux";
import { markLoggedOut } from "../redux/authslice";

export default function AppLogout() {
  const isUserLoggedIn = useSelector((state) => {
    console.log("AppLogout : state.auth is : ", state.auth);
    return state.auth.isLoggedIn;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // const user = await getCurrentUser();
        console.log("calling signOut");
        signOut();
        console.log("signOut call done");
        {
          dispatch(markLoggedOut());
        }
        // setIsLoggedIn(false);
        console.log("setIsLoggedIn false done");
      } catch (error) {
        // setIsLoggedIn(true);
        console.error("Error checking authentication state:", error);
      }
    };

    checkAuth();
  }, []);

  const onLoggedOut = () => {
    // console.log("user not logged in ");
    return (
      <>
        <TopNavbar />
        <Header />
        <Services />
        <Projects />
        {/* <Blog /> */}
        {/* <Pricing /> */}
        {/* <Contact /> */}
        <Footer />
      </>
    );
  };

  return (
    <div>
      {isUserLoggedIn ? <div> Logging Out... </div> : <Navigate to="/" />}
    </div>
  );
}
