import React, { useState, useEffect } from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.png";

import { Button } from "semantic-ui-react";
import { Link, Navigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { PortfolioLanding } from "./Portfolio/PortfolioLanding";
import WpiConstants from "../../utils/wpiconstants";

import { LogoutLandingBtnWrapper } from "../../common/MySipStyles";
import { Spinner } from "flowbite-react";
import Avatar from "react-avatar";

export default function LoggedInCreatePortfolio() {
  const userName = useSelector((state) => {
    // console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
    return state.auth.user;
  });

  const [createdPortfolios, setCreatedPortfolios] = useState(null);
  const [followedPortfolios, setFollowedPortfolios] = useState([]);
  const [portfolioId, setPortfolioId] = useState(null);
  const [topPortfolioResponse, setTopPortfolioResponse] = useState(null);

  const top_portfolio_func = async () => {
    const top_portfolio_url = "";
    const top_portfolio_response = await fetch(
      WpiConstants.GET_TOP_PORTFOLIO_URL
    );
    if (top_portfolio_response.ok) {
      const json_data = await top_portfolio_response.json();
      console.log("top portfolios response is : ", json_data);

      setTopPortfolioResponse(json_data);
    }
  };

  const fetch_portfolios_created = async () => {
    console.log(
      "inside fetch_portfolios_created api : top_portfolios is : ",
      topPortfolioResponse
    );
    const portfolio_start_dt = "2024-04-19";
    const component_arr = [];

    const requestBody = { user: userName };
    const url = WpiConstants.GET_FOLLOWERS_URL;

    console.log("LoggedInCreatePortfolio api body is : \n", requestBody);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    console.log("api response : ", response);
    if (response.ok) {
      const json_res = await response.json();
      setCreatedPortfolios(json_res["data"]["portfolios_created"]);
      setFollowedPortfolios(json_res["data"]["portfolios_followed"]);
      console.log("json response is : ", json_res);
    } else {
      const json_res = await response.text();
      console.log("error in api response : ", json_res);
    }

    console.log(
      "after api response fetch_portfolios_created api : top_portfolios is : ",
      topPortfolioResponse
    );

    // setTimeout( () => { console.log("displaying chart"); setRedirectToChart(true); }, 1000);
  };

  useEffect(() => {
    // Function to fetch data from the API
    setPortfolioId(null);
    fetch_portfolios_created(); // Call the fetchData function when the component mounts
    top_portfolio_func();
  }, []);

  const openPortfolio = (id) => {
    console.log("Opening portfolio : ", id);
    setPortfolioId(id);
    // return <PortfolioLanding data={id} />;
    setNavigate("/portfolio/landing/");
  };

  const mainUINew = () => {
    return (
      <section class="bg-gray-200 dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
          <div class="grid md:grid-cols-1 gap-8">
            <div class="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
              <h2 class="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">
                Created Portfolios
              </h2>
              <div class="grid grid-cols-4 gap-4">
                {createdPortfolios.map((item, index) => (
                  <div>
                    <ProjectBox
                      img={ProjectImg1}
                      title={item.name}
                      text={item.name + " created"}
                      action={() => openPortfolio(item.id)}
                    />
                  </div>
                ))}
              </div>
              <br />
              <a
                href="#"
                class="text-blue-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center"
              >
                Show more
                <svg
                  class="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
              <GetTopPerformingPortfolios
                topPortfoliosArr={topPortfolioResponse}
                openPortfolioFunc={openPortfolio}
              />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const showSpiner = () => {
    return (
      <div className="w-screen h-40 flex items-center justify-center">
        <Spinner
          color="purple"
          aria-label="Extra small spinner example"
          size="xl"
        />
      </div>
    );
  };

  console.log("createdPortfolios : ", createdPortfolios);

  const mainUI = () => {
    return (
      <div className=" w-full flex items-center justify-center">
        <div className="w-full sm:w-11/12 flex flex-col items-center p-4 ">
          <div className=" flex flex-col text-center">
            <h2 className="font-serif text-4xl mb-4  tracking-normal">
              Your Created Portfolios
            </h2>
          </div>
          <div className="w-full sm:w-11/12 ">
            {createdPortfolios ? (
              <div className=" mt-10 flex items-center justify-center ml-0 mr-0">
                <div className="flex overflow-x-auto space-x-4 p-0 bg-violet-100 border border-blue-400 rounded-lg">
                  {createdPortfolios.map((item, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0  m-4 bg-gray-100 border border-blue-100 rounded-lg text-center"
                      onClick={() => openPortfolio(item["id"])}
                    >
                      <div className="block max-w-sm p-4 h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        {/* {item[0]} */}
                        <div className="flex flex-col m-0 p-0 items-center w-52  h-full ">
                          <Avatar name={item["name"]} size="100" round />

                          <div className="text-xl font-sans font-semibold mt-4 ">
                            <p className="line-clamp-2 min-h-[4rem]">
                              {item["name"]}
                            </p>
                          </div>

                          <div className="w-full h-0.5 bg-orange-500 mb-2 mt-2"></div>

                          <div className=" w-full m-0 p-2 sm:p-0 ">
                            <div className="mt-0 flex flex-row w-full m-0 p-0 items-center">
                              <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                                Sharpe Ratio &nbsp;
                              </div>
                              <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                                {item["Sharpe Ratio"]}
                              </div>
                            </div>
                            <div className="mt-2 flex flex-row w-full m-0 p-0">
                              <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                                CAGR &nbsp;
                              </div>
                              <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                                {item["CAGR"]}
                              </div>
                            </div>
                            <div className="mt-2 flex flex-row w-full m-0 p-0">
                              <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                                Max Drawdown &nbsp;
                              </div>
                              <div className="text-right font-sans text-sm sm:text-lg tracking-wider font-medium text-red-600">
                                {item["Max Drawdown"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              showSpiner()
            )}
          </div>

          <div className="flex flex-col text-center mt-20">
            <h2 className="font-serif text-4xl mb-4  tracking-normal">
              Top Performing Portfolios
            </h2>
          </div>
          <div className="w-full sm:w-11/12  ">
            {topPortfolioResponse ? (
              <div className="mt-10 flex items-center justify-center ml-0 mr-0">
                <div className="flex overflow-x-auto space-x-4 p-0 bg-violet-100 border border-blue-400 rounded-lg">
                  {topPortfolioResponse.map((item, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0 m-4 bg-gray-100 border border-blue-100 rounded-lg text-center"
                      onClick={() => openPortfolio(item["id"])}
                    >
                      <div className="block max-w-sm p-4 h-full bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        {/* {item[0]} */}
                        <div className="flex flex-col m-0 p-0 items-center w-52  h-full ">
                          <Avatar name={item["name"]} size="100" round />

                          <div className="text-xl font-sans font-semibold mt-4 ">
                            <p className="line-clamp-2 min-h-[4rem]">
                              {item["name"]}
                            </p>
                          </div>

                          <div className="w-full h-0.5 bg-orange-500 mb-2 mt-2"></div>

                          <div className=" w-full m-0 p-2 sm:p-0 ">
                            <div className="mt-0 flex flex-row w-full m-0 p-0 items-center">
                              <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                                Today's Return &nbsp;
                              </div>
                              <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                                {item["ret"]}
                              </div>
                            </div>
                            <div className="mt-2 flex flex-row w-full m-0 p-0">
                              <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                                CAGR &nbsp;
                              </div>
                              <div className="text-right font-sans text-sm sm:text-lg tracking-tight font-medium">
                                {item["CAGR"]}
                              </div>
                            </div>
                            <div className="mt-2 flex flex-row w-full m-0 p-0">
                              <div className="flex-auto  text-left font-serif tracking-widest font-normal text-sm sm:text-lg">
                                Max Drawdown &nbsp;
                              </div>
                              <div className="text-right font-sans text-sm sm:text-lg tracking-wider font-medium text-red-600">
                                {item["Max Drawdown"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              showSpiner()
            )}
          </div>

         
        </div>
      </div>
    );
  };

  const [navigate, setNavigate] = useState();

  return (
    <div className="mb-20">
      {navigate ? (
        <div>
          {console.log("navigating to : ", navigate)}
          <Navigate to={navigate} state={{ portfolioId: portfolioId }} />
        </div>
      ) : (
        <div>{topPortfolioResponse != null ? mainUI() : <div> </div>}</div>
      )}
    </div>
  );
}

const GetTopPerformingPortfolios = ({
  topPortfoliosArr,
  openPortfolioFunc,
}) => {
  console.log("GetTopPerformingPortfolios : ", topPortfoliosArr);

  if (topPortfoliosArr == null || topPortfoliosArr.length == 0) {
    return <div></div>;
  }

  console.log("GetTopPerformingPortfolios :: toggleTableVisibility ");

  let headingArr = topPortfoliosArr[0];
  let tableBodyData = topPortfoliosArr.slice(1);
  let headingKeys = Object.values(headingArr);
  console.log("headingKeys : ", headingKeys);

  console.log("topPortfoliosArr : ", tableBodyData);

  const currentRows = tableBodyData;

  const mainUI = () => {
    return (
      <div className="w-full md:w-1/2 p-0 ">
        <div className="text-black  text-center font-bold text-lg">
          Top Portfolios
        </div>
        <div className="w-full ">
          <table
            id="table-report-yearly"
            class="  w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8 mb-5"
          >
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="border border-black bg-black text-white text-xs">
                {headingKeys.map((item, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="md:px-6 px-2 md:pl-20 md:py-3 py-2 text-sm md:text-xs"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((item, index) => (
                <tr
                  key={index}
                  scope="row"
                  class="border border-black  bg-white dark:bg-gray-800 dark:border-gray-700 hover:bg-green-800"
                  onClick={() => openPortfolioFunc(item[0])}
                >
                  <th
                    scope="row"
                    class="border border-black md:px-6 px-2  md:pl-20 py-4 text-sm md:text-xs font-extrabold text-gray-900 whitespace-nowrap dark:text-white bg-violet-200"
                  >
                    {item[1]}
                  </th>
                  <th
                    className={`px-6 font-extrabold md:pl-20 text-sm ${
                      item[2] < 0 ? "text-red-600" : "text-black"
                    } bg-white`}
                  >
                    {item[2]}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return <div>{mainUI()}</div>;
};
