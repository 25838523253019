import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authslice";
import portfolioDataReducer from "./portfoliodataslice";
import sectorDataReducer from "./sectordataslice";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const reHydrateStore = () => {
  if (localStorage.getItem("appstate") !== null) {
    return JSON.parse(localStorage.getItem("appstate"));
  }
};

const authMiddleWare = (storeAPI) => (next) => (action) => {
  const result = next(action);
  localStorage.setItem("appstate", JSON.stringify(storeAPI.getState()));
  return result;
};

const appStore = configureStore({
  reducer: {
    auth: authReducer,
    portfolioData: portfolioDataReducer,
    sectorData: sectorDataReducer,
  },
  preloadedState: reHydrateStore(),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleWare),
});
initMessageListener(appStore);

export default appStore;
