import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { ColorType, createChart } from "lightweight-charts";

export const Sector = ({ sectorKeys, tfKeys, data }) => {
  const [sectorKey, setSectorKey] = useState();
  const [tfKey, setTfKey] = useState();

  const [stockList, setStockList] = useState([]);
  const [preparedDataDict, setPreparedDataDict] = useState({});
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const [updateUI, setUpdateUI] = useState(false);

  const onLeftDropDownSelect = (opt) => {
    setSectorKey(opt);
  };

  const onRightDropDownSelect = (opt) => {
    setTfKey(opt);
  };

  useEffect(() => {
    console.log("sectorKeys : ", sectorKeys);
    console.log("tfKey : ", tfKeys);
    if (
      sectorKeys != null &&
      tfKeys != null &&
      data != null &&
      Object.keys(data).length > 0
    ) {
      setSectorKey(sectorKeys[0]);
      setTfKey(tfKeys[0]);
      setUpdateUI(true);
    }
  }, [sectorKeys, tfKeys, data]);

  useEffect(() => {
    if (
      data != null &&
      Object.keys(data).length > 0 &&
      data[sectorKey] != null
    ) {
      console.log("Sector :: data : ", data);
      console.log("Sector :: sectorKey : ", sectorKey);
      let entries = data[sectorKey];

      let sList = Object.keys(entries);
      let eqList = [];
      let ret = {};
      for (let i = 0; i < sList.length; i++) {
        const stock = sList[i];
        eqList.push(stock);
        console.log("for loop : stock : ", stock, " tfKey : ", tfKey);
        ret[stock] = entries[stock][tfKey];
      }
      console.log("setting stock list : ", eqList);
      setStockList(eqList);
      setPreparedDataDict(ret);
      setTimeout(() => {
        setIsDataAvailable(true);
      }, 1000);
      console.log("updated return value is : ", ret);
    }
  }, [sectorKey, tfKey]);

  return (
    <div>
      <Wrapper>
        <div id="container">
          <div className="flex flex-col">
            <div className="flex justify-center font-bold items-center h-16 text-3xl">
              Sectoral Analysis
            </div>
            <div className="flex justify-between z-10">
              <div className="relative inline-block text-left">
                <Dropdown
                  options={sectorKeys.sort()}
                  onSelect={onLeftDropDownSelect}
                />
              </div>

              <div className="relative inline-block text-left">
                <Dropdown options={tfKeys} onSelect={onRightDropDownSelect} />
              </div>
            </div>
            <div className="mt-5">
              {isDataAvailable ? (
                <SectorChart
                  stocklist={stockList.sort()}
                  data_arr={preparedDataDict}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const SectorChart = ({ stocklist, data_arr }) => {
  const parentDivRef = useRef(null);
  const idxRef = useRef(null);
  const parent1 = useRef(null);
  const parent2 = useRef(null);

  const removeAllChildren = () => {
    const parentDiv = parentDivRef.current;

    if (parentDiv) {
      while (parentDiv.firstChild) {
        parentDiv.removeChild(parentDiv.firstChild);
      }
    }
  };

  const colorArr = [
    "#2b2828",
    "#de140d",
    "#787246",
    "#f0790a",
    "#eb64ed",
    "#0b800d",
    "#0920b5",
    "#8b0cf2",
    "#471547",
    "#f51b76",
  ];

  const chart_width = 800;
  const chart_height = 400;
  const textColor = "#000000";

  const getCalcWidth = () => {
    const isMedium = window.matchMedia("(min-width: 768px)").matches;
    console.log("Sector :: isMedium : ", isMedium);
    let calcWidthDiff = 10;
    if (isMedium) {
      calcWidthDiff = 20;
    }
    return calcWidthDiff;
  };

  useEffect(() => {
    const idx_comparision_container =
      document.getElementById("idx_comparision");

    const chart = createChart(idx_comparision_container, {
      layout: {
        background: { type: ColorType.Solid, color: "white" },
        textColor,
      },
      width: idxRef.current.clientWidth - getCalcWidth(),
      height: chart_height,
    });
    chart.timeScale().fitContent();

    const legendContainer = document.getElementById("legend_id");

    removeAllChildren();
    // removeAllChildren(idx_comparision_container);
    console.log("Sector::stockList : ", stocklist);
    console.log("Sector:: data_arr : ", data_arr);
    let currentColorArr = [];
    for (let i = 0; i < stocklist.length; i++) {
      let curr_color = colorArr[i];
      currentColorArr.push(curr_color);
      const idxSeries = chart.addLineSeries({ color: curr_color });
      idxSeries.setData(data_arr[stocklist[i]]);
      const legend = document.createElement("div");
      //   legend.style = `position: absolute; left: 12px; top: 12px; z-index: 1; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 300;`;
      legend.style.color = curr_color;
      legend.style.fontWeight = "bold";
      legend.innerHTML = `<div> ${stocklist[i]} </div>`;
      legendContainer.appendChild(legend);
    }

    const handleResize = () => {
      //   chart.applyOptions(chartOptions);
      chart.resize(idxRef.current.clientWidth - getCalcWidth(), 400);
      chart.timeScale().fitContent();
      const isMedium = window.matchMedia("(min-width: 768px)").matches;
    };

    window.addEventListener("resize", handleResize);

    chart.timeScale().fitContent();

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [data_arr]);

  return (
    <div className="bg-gray-200 dark:bg-gray-900" ref={parent1}>
      <div className="flex flex-col md:flex-row m-0 md:m-5" ref={parent2}>
        <div id="idx_comparision" ref={idxRef} className="flex-grow"></div>
        <div>
          <div id="legend_id" className="ml-0" ref={parentDivRef}></div>
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    console.log("updating dropdown hover");
    setActiveOption(options[0]);
  }, []);

  const [activeOption, setActiveOption] = useState("");

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={toggleDropdown}
      >
        {activeOption}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div className=" absolute  rounded-lg shadow-lg bg-white divide-y divide-gray-100 dark:bg-gray-700">
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownHoverButton"
          >
            {options.map((option, index) => (
              <li key={index}>
                <a
                  href="#"
                  className={`block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                    activeOption === option
                      ? "bg-gray-100 dark:bg-gray-600 dark:text-white"
                      : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const TopWrapper = styled.div`
  display: grid;
  place-items: center; /* Vertically and horizontally center the content */
  height: 40vh; /* Ensure the wrapper takes up the full viewport height */
`;

const Wrapper = styled.section`
  width: 100%;
  margintop: 200px;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;

const ButtonWrapper = styled(Button)`
  margin: 10px;
  background-color: red;
  max-width: 190px;
  height: 40px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
