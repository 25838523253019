import React, { useEffect } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

import PCreate from "../components/Sections/Portfolio/Create";
import LoggedInTopNavbar from "../components/Nav/LoggedInTopNavbar";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function PortfolioCreate() {
  const isUserLoggedIn = useSelector((state) => {
    // console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
    return state.auth.isLoggedIn;
  });

 

  return (
    <>
      {!isUserLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <div>
          <LoggedInTopNavbar />
          {/* <Header /> */}
          <PCreate />
          {/* <Blog /> */}
          {/* <Pricing /> */}
          {/* <Contact /> */}
          <Footer />
        </div>
      )}
    </>
  );
}
