import _ from "lodash";

import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { createChart, ColorType, LineType } from "lightweight-charts";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { fetchSectorData, resetData } from "../../../redux/sectordataslice";
import PortfolioChart from "./PortfolioChart";
import { Sector } from "./Sector";
import LoggedInTopNavbar from "../../Nav/LoggedInTopNavbar";

export const PortfolioSector = () => {
  //   const { id } = useParams(); // Access URL parameter
  console.log("PortfolioSector");
  const isUserLoggedIn = useSelector((state) => {
    // console.log("AppLanding state auth is : ", state.auth.isLoggedIn);
    return state.auth.isLoggedIn;
  });

  const [sectorKey, setSectorKey] = useState([]);
  const [tfKey, setTFKey] = useState([]);
  const [openChart, setOpenChart] = useState(false);

  const dispatch = useDispatch();
  const landing = useLocation();
  // console.log("PortfolioLanding location is : ", landing);

  // const sectorFetchLoading = useSelector((state) => state.sectorData.loading);

  const [sectorFetchLoading, setSectorFetchLoading] = useState(true);

  const data = useSelector((state) => state.sectorData.data);
  // console.log("sector data is : ", data);
  // console.log("Keys : ", Object.keys(data));

  useEffect(() => {
    if (data != null) {
      let key1 = Object.keys(data)[0];
      let obj1 = data[key1];
      if (obj1) {
        let key2 = Object.keys(obj1)[0];
        let obj2 = obj1[key2];
        setSectorKey(Object.keys(data));
        setTFKey(Object.keys(obj2));
        setTimeout(() => {
          setSectorFetchLoading(false);
        }, 1000);

        // setOpenChart(true);
      }
    }
  }, [data]);

  useEffect(() => {
    console.log("useEffect");
    // dispatch(resetData());
    // if (isUserLoggedIn) {
    const ret = dispatch(fetchSectorData());
    // }
  }, []);

  return (
    <div
      className="container"
      style={{ width: "100%", minWidth: "100%", background: "#ffffff" }}
    >
      <LoggedInTopNavbar />
      <div class="w-full p-4 my-4  rounded-lg bg-gray-200 dark:bg-gray-900">
        <div>
          <div>
            {sectorFetchLoading ? (
              <div> Sector Loading </div>
            ) : (
              <div>
                <Sector sectorKeys={sectorKey} tfKeys={tfKey} data={data} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
